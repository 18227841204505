/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from "react";
import { Container, TextField, Card, Box } from "@material-ui/core";

import { useText, useGetData, usePutData, usePostData } from "hooks";
import useStore from "context";

import * as Api from "service";
import { Lang, Save } from "components";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "localization/translation";
import { MANAGER_ROLE } from "../constants";
import {
  NAME_EN,
  NAME_HY,
  NAME_RU,
  PATHNAMES,
  REQUIRED_ERROR_MESSAGE,
} from "Constants";

const initForm = {
  name_en: "",
  name_hy: "",
  name_ru: "",
};

const Form = () => {
  const { id } = useParams();
  const { setErrorMsg, lang, user } = useStore();
  const translation = useTranslation();
  const history = useHistory();

  const apiCall =
    user.role === MANAGER_ROLE ? Api.goodsTypeManager : Api.goodsType;

  const [data, , refresh] = useGetData(id && apiCall.getById, id);
  const postCallback = usePostData(apiCall.create, refresh);
  const updateCallback = usePutData(apiCall.update, refresh);

  const [text, setText, , input, error, setError] = useText(data || initForm);

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();
      setError({});

      const languages = [NAME_EN, NAME_RU, NAME_HY];

      if (languages.every((el) => !text[el])) {
        return setErrorMsg(translation.language_error_type_message);
      }

      const title = languages
        .map((lang) => text[lang])
        .find((value) => !!value);

      const data = { ...text };

      languages.forEach((lang) => {
        if (!data[lang]) {
          data[lang] = title;
        }
      });

      id ? updateCallback(text) : postCallback(data);
    },
    [id, postCallback, setErrorMsg, text, updateCallback]
  );

  const validateField = (field, value) => {
    let isValid = true;

    switch (field) {
      case NAME_EN:
        isValid = !!value;
        break;
      default:
        isValid = true;
    }

    setError((prevErrors) => ({
      ...prevErrors,
      [field]: !isValid,
    }));
  };

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setText((prev) => ({ ...prev, [name]: value }));
    validateField(name, value);
  };

  useEffect(() => {
    if (data && data?.related_good_type_id) {
      history.push(PATHNAMES.good_types);
    }
  }, [data]);

  return id ? (
    !!data && (
      <Container>
        <Card className="shadow-xxl px-4 py-2">
          <Box component="form" onSubmit={submitForm}>
            <Lang />
            <TextField
              {...input(`name_${lang}`)}
              label={translation.name}
              onChange={handleTextChange}
            />
            <Save />
          </Box>
        </Card>
      </Container>
    )
  ) : (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <Lang />
          <TextField
            {...input(`name_${lang}`)}
            label={translation.name}
            onChange={handleTextChange}
          />
          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default Form;
