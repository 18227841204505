/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TitleSubtitle from "components/titlie-subtitle";
import styles from "./styles.module.scss";
import * as Api from "service";
import useStore from "context";

const imageBaseUrl = process.env.REACT_APP_imagesBaseUrl;

const ReadyToImprove = () => {
  const { language } = useStore();
  const [imageSection, setImageSection] = useState({});

  const image_element = document.getElementById("image_section");

  useEffect(() => {
    Api.main.getImageSection().then(({ data }) => {
      setImageSection(data);
    });
  }, []);

  useEffect(() => {
    if (imageSection?.image) {
      image_element.style.backgroundImage = `url('${imageBaseUrl}${imageSection.image}')`;
    }
  }, [imageSection]);

  return (
    <div className={styles.main_content} id="image_section">
      <TitleSubtitle
        subtitle={imageSection?.[`title_${language}`]}
        text={imageSection?.[`description_${language}`]}
        subtitleClassName={styles.subtitle}
        textClassName={styles.text}
      />
      {imageSection?.button_link ? (
        <Link to={imageSection.button_link} className={styles.get_started}>
          {imageSection?.[`button_text_${language}`]}
        </Link>
      ) : (
        <span className={styles.get_started}>
          {imageSection?.[`button_text_${language}`]}
        </span>
      )}
    </div>
  );
};

export default ReadyToImprove;
