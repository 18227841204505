import React, { useState, useCallback, useEffect } from "react";
import "./style.scss";
import * as Api from "../../service";
import { useTextFront } from "../../hooks";
import withHeaderAndFooter from "components/with-header-and-footer-hoc";
import { useParams, useHistory } from "react-router";
import { ReactComponent as Loading } from "../../assets/icons/loading.svg";
import { useTranslation } from "localization/translation";
import { REQUIRED_ERROR_MESSAGE } from "Constants";
import PasswordInput from "shared/components/PasswordInput";

const initForm = {
  password: "",
  confirmPassword: "",
};

const Invitation = () => {
  const translation = useTranslation();
  const [text, setText, , input] = useTextFront(initForm);
  const [error, setError] = useState({
    password: "",
    confirmPassword: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(true);
  const [tokenErrorText, setTokenErrorText] = useState(false);
  const [errorFields, setErrorFields] = useState({
    password: false,
    confirmPassword: false,
  });

  const history = useHistory();

  let { token } = useParams();

  useEffect(() => {
    (async () => {
      try {
        setSuccess("");
        setError({
          password: "",
          confirmPassword: "",
        });
        const response = await Api.sign.invitationCheck(token);
        if (response.success) {
          setLoading(false);
        } else {
          throw new Error("Token isn't valid");
        }
      } catch (err) {
        setLoading(false);
        setTokenErrorText("Invalid Token");
      }
    })();
  }, [token]);

  const submit = useCallback(
    async (e) => {
      e.preventDefault();
      setIsSubmitted(true);

      const errors = {
        password: !text.password || text?.password.length < 8,
        confirmPassword: !text.confirmPassword,
      };

      if (Object.values(errors).some((hasError) => hasError)) {
        setErrorFields(errors);
        if (!!text.password && text?.password.length < 8) {
          setError((prev) => ({
            ...prev,
            password: translation.invalid_password_message,
          }));
        }
        return;
      }

      if (text.password !== text.confirmPassword) {
        setErrorFields(() => ({ ...errors, confirmPassword: true }));
        setError((prev) => ({
          ...prev,
          confirmPassword: translation.passwords_must_match,
        }));
        return;
      }

      try {
        setSuccess("");
        setError({
          password: "",
          confirmPassword: "",
        });
        await Api.sign.invitationActivation(token, { password: text.password });
        setSuccess(translation.account_activated);
        history.replace("/sign_in");
      } catch (err) {
        console.log("err", err.message);
      }
    },
    [text, token, history]
  );

  const validateField = (field, value) => {
    let isValid = false;

    if (isSubmitted) {
      switch (field) {
        case "password":
          isValid = !!value;
          if (!!value && value.length < 8) {
            setError((prev) => ({
              ...prev,
              password: translation.invalid_password_message,
            }));
            isValid = false;
          }
          break;
        case "confirmPassword":
          isValid = !!value;
          if (text.password !== value) {
            setError((prev) => ({
              ...prev,
              confirmPassword: translation.passwords_must_match,
            }));
            isValid = false;
          }
          break;
        default:
          isValid = true;
      }

      setErrorFields((prevErrors) => ({
        ...prevErrors,
        [field]: !isValid,
      }));
    }
  };

  const handleChange = (e) => {
    const { value, name } = e.target;

    if (value !== " ") {
      setText((prevText) => ({
        ...prevText,
        [name]: value.trim(),
      }));
      validateField(name, value.trim());
    }
  };

  return (
    <section className="invitation-section">
      {tokenErrorText && <span className="token_error"> {tokenErrorText}</span>}
      {loading ? (
        <div className="load_icon">
          <Loading />
        </div>
      ) : (
        !tokenErrorText && (
          <div className="main-container">
            <h2>{translation.account_activation}</h2>
            <form onSubmit={submit}>
              <h3>{translation.enter_password_account}</h3>
              <div className="sing-by-mail">
                <PasswordInput
                  {...input.password}
                  type="password"
                  placeholder={translation.password}
                  error={errorFields.password}
                  errorMessage={
                    errorFields?.password &&
                    (error.password || REQUIRED_ERROR_MESSAGE)
                  }
                  onChange={(e) => handleChange(e)}
                />
                <PasswordInput
                  {...input.confirmPassword}
                  type="password"
                  placeholder={translation.repeat_password}
                  error={errorFields.confirmPassword}
                  errorMessage={
                    errorFields.confirmPassword &&
                    (error.confirmPassword || REQUIRED_ERROR_MESSAGE)
                  }
                  onChange={(e) => handleChange(e)}
                />
                <span className="success">{success}</span>
                <button type="submit" className="submit-btn">
                  {translation.save}
                </button>
              </div>
            </form>
          </div>
        )
      )}
    </section>
  );
};

export default withHeaderAndFooter(Invitation);
