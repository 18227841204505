import React, { useEffect } from "react";
import { Link, useParams, useLocation, useHistory } from "react-router-dom";
import { useGetData } from "hooks";
import * as Api from "service";
import { WrapperSeamless } from "layout-components";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Grid,
  Tooltip,
} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ViewIcon from "@material-ui/icons/Visibility";
import Base from "service/base";
import { useTranslation } from "localization/translation";
import useStore from "context";
import { PDF, EXCEL, SEARCH, NO_RESULT, USER_ROLE } from "Constants";
import {
  changeVolumeValue,
  getLeftOver,
  getMeasurementName,
} from "helpers/getMeasurementName";
import { PdfExcelDropdown } from "components";
import "./list.scss";

const imageBase = process.env.REACT_APP_imagesBaseUrl;

const View = () => {
  const {
    inventoryIds,
    setInventoryIds,
    language,
    weightValue,
    volumeValue,
    setInventoryModal,
    user,
  } = useStore();
  const translation = useTranslation();
  const { id, did } = useParams();
  const query = new URLSearchParams(useLocation().search);
  const search = query.get(SEARCH);
  const history = useHistory();

  const exportParam = true;

  const [items] = useGetData(Api.inventories.getById, id, search);
  const [inventories] = useGetData(Api.getInventoryPrepare.getById, id, search);

  const handleRoutePrepare = () => {
    setInventoryModal(!!inventories?.data?.unresolvedCount);
    history.push(`/dashboard/inventories-prepare/${id}`);
  };

  const exportExcel = (id, extended) => {
    const api = new Base();
    api.getRequest("/v2/export/excel/" + id, { extended }).then((res) => {
      const downloader = document.getElementById("downloader");
      downloader.href = res.path;
      downloader.click();
    });
  };

  const exportPdf = (id, extended) => {
    const api = new Base();
    api.getRequest("/v2/export/pdf/" + id, { extended }).then((res) => {
      const downloader = document.getElementById("downloader");
      downloader.href = res.path;
      setTimeout(() => {
        downloader.click();
      }, 2000);
    });
  };

  useEffect(() => {
    if (items?.data) {
      const ids = items?.data?.map((el) => el.id);
      setInventoryIds(ids);
    }
  }, [items]);

  return (
    <WrapperSeamless>
      <Card className="card-box mb-spacing-6-x2">
        <CardContent>
          <div className="inventory_header">
            <h6 className="font-weight-bold font-size-lg mb-0 text-black">
              {translation.inventory} #{did}
            </h6>
            <div className="right_side">
              <div
                className={`iiko_buttons ${
                  user?.role === USER_ROLE.customer ||
                  user?.role === USER_ROLE.manager
                    ? ""
                    : "hide_button"
                }`}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={handleRoutePrepare}
                >
                  {translation.next}
                </Button>
              </div>
              <h6 className="font-weight-bold font-size-lg mb-0 text-black">
                {translation.export_as}
                <PdfExcelDropdown
                  name={translation.export_pdf}
                  extendedName={translation.export_extended_pdf}
                  exportFile={() => exportPdf(id, !exportParam)}
                  exportExtendedFile={() => exportPdf(id, exportParam)}
                  children={<span>{PDF}</span>}
                  title={translation.export_pdf}
                  customClass="inventory_pdf_excel"
                />
                <span>|</span>
                <PdfExcelDropdown
                  name={translation.export_excel}
                  extendedName={translation.export_extended_excel}
                  exportFile={() => exportExcel(id, !exportParam)}
                  exportExtendedFile={() => exportExcel(id, exportParam)}
                  children={<span>{EXCEL}</span>}
                  title={translation.export_excel}
                  customClass="inventory_pdf_excel"
                />
              </h6>
            </div>
          </div>
          <Grid container justifyContent="center">
            {!!items?.data?.length
              ? items?.data?.map((item, index) => {
                  return (
                    <Grid className={`mt-3`} key={index} xs={10} md={12} item>
                      <Accordion
                        expanded={inventoryIds.includes(item.id)}
                        onChange={(e, expanded) => {
                          if (expanded)
                            setInventoryIds((prev) => [...prev, item.id]);
                          if (!expanded && inventoryIds.includes(item.id)) {
                            const ids = inventoryIds.filter(
                              (id) => id !== item.id
                            );
                            setInventoryIds(ids);
                          }
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>{item?.name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <table className="table">
                            <thead className="text-center">
                              <th>{translation.image}</th>
                              <th>{translation.name}</th>
                              <th>{translation.barcode}</th>
                              <th>{translation.bottle_volume}</th>
                              <th>{translation.left_over}</th>
                              <th>{translation.action}</th>
                            </thead>
                            <tbody className="text-center">
                              {item?.goods?.map((good) => {
                                return (
                                  <tr key={good.id}>
                                    <td className="d-flex justify-content-center">
                                      <Avatar
                                        className="mr-0"
                                        alt={good?.name_en}
                                        src={
                                          good?.image
                                            ? `${imageBase}${good?.image}`
                                            : imageBase
                                        }
                                      />
                                    </td>
                                    <td>
                                      <b>
                                        {`${good?.[`name_${language}`]}
                                                                    (${getMeasurementName(
                                                                      good?.measurement_unit,
                                                                      language,
                                                                      weightValue,
                                                                      volumeValue
                                                                    )})`}
                                      </b>
                                    </td>
                                    <td>{good?.barcode}</td>
                                    <td>
                                      {good?.litrage
                                        ? changeVolumeValue(
                                            good?.litrage,
                                            volumeValue
                                          )
                                        : "-"}
                                    </td>
                                    <td
                                      className={
                                        good?.added_after_completed
                                          ? "blue_status"
                                          : good?.status?.toLowerCase()
                                      }
                                    >
                                      {getLeftOver({
                                        unit: good?.measurement_unit,
                                        totalLiters: good?.total_liters,
                                        leftOver: good?.total_leftover,
                                        language,
                                        weight: weightValue,
                                        volume: volumeValue,
                                      })}
                                    </td>
                                    <td>
                                      <Tooltip
                                        placement={`top`}
                                        title="View details"
                                      >
                                        <Link
                                          to={{
                                            pathname: `/dashboard/inventories/${id}/${good.id}/${did}`,
                                            state: {
                                              leftover: getLeftOver({
                                                unit: good?.measurement_unit,
                                                totalLiters: good?.total_liters,
                                                leftOver: good?.total_leftover,
                                                language,
                                                weight: weightValue,
                                                volume: volumeValue,
                                              }),
                                            },
                                          }}
                                        >
                                          <Button className="btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
                                            <ViewIcon color="primary" />
                                          </Button>
                                        </Link>
                                      </Tooltip>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  );
                })
              : search && <p>{NO_RESULT}</p>}
          </Grid>
        </CardContent>
      </Card>
      <a
        style={{ display: "none" }}
        href={`/`}
        id={`downloader`}
        target={`_blank`}
        download
      >
        {translation.download}
      </a>
    </WrapperSeamless>
  );
};

export default View;
