import React, { useCallback, useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { useTextFront } from "hooks";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { ReactComponent as BackArrow } from "assets/images/backArrow.svg";
import { useTranslation } from "localization/translation";
import { isValidField, isValidForm } from "helpers/formValidation";
import Header from "components/header";
import { HEADER_URLS, PASSWORD } from "Constants";
import useStore from "context";
import * as Api from "service";
import PasswordInput from "shared/components/PasswordInput";

const initForm = {
  password: "",
  repeat_password: "",
};

const validation = {
  password: "password:required",
  repeat_password: "password:required",
};

const SetPassword = ({ title, subTitle, buttonText, request }) => {
  const params = useParams();
  const history = useHistory();
  const { language } = useStore();
  const [text, setText, , input] = useTextFront(initForm);
  const [error, setError] = useState("");
  const [fieldError, setFieldError] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const translation = useTranslation();

  const submit = useCallback(
    async (e) => {
      e.preventDefault();
      setIsSubmitted(true);
      try {
        if (isValidForm({ text, validation, setFieldError, lang: language })) {
          const data = {
            password: text.password,
          };
          setError("");
          const requestParams = { token: params?.token, data };
          if (params?.email) {
            requestParams.email = params?.email;
          }
          await request(requestParams);
          setText(initForm);
          history.push("/sign_in");
        }
      } catch (err) {
        setError(err.message);
      }
    },
    [text]
  );

  const isDisabled = !(
    !!text.password && text.password === text.repeat_password
  );

  useEffect(() => {
    (async () => {
      const isRegisterContinuePage = history.location.pathname.includes(
        "register/continue"
      );
      if (params?.token && !isRegisterContinuePage) {
        try {
          const response = await Api.sign.tokenCheck(params?.token);
          if (!response.success) {
            throw new Error("Token is'nt valid");
          }
        } catch (err) {
          history.push("/");
        }
      }
    })();
  }, []);

  return (
    <section className={styles.setPassword}>
      <Header url={HEADER_URLS.reset_password} />
      <div className={styles.mainContainer}>
        <div className={styles.titleSection}>
          <BackArrow onClick={() => history.goBack()} />
          <h2>{title}</h2>
        </div>
        <p>{subTitle}</p>
        <form onSubmit={submit}>
          <div className={styles.singByMail}>
            <PasswordInput
              {...input.password}
              error={
                isSubmitted &&
                isValidField({
                  value: text.password,
                  type: PASSWORD,
                  required: true,
                })
              }
              helpText={
                isValidField({
                  value: text.password,
                  type: PASSWORD,
                  required: true,
                }) && fieldError?.password
              }
              type="password"
              placeholder={translation.password}
            />
            <PasswordInput
              {...input.repeat_password}
              error={
                isSubmitted &&
                isValidField({
                  value: text.password,
                  type: PASSWORD,
                  required: true,
                })
              }
              type="password"
              placeholder={translation.repeat_password}
            />
            <button
              type="submit"
              className="submit-btn"
              disabled={isDisabled}
              style={{ opacity: isDisabled && 0.5 }}
            >
              {buttonText}
            </button>
            <span className={styles.error}>{error}</span>
          </div>
        </form>
      </div>
    </section>
  );
};

export default SetPassword;
