import React, { useEffect, useState } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useGetData } from "hooks";
import * as Api from "service";
import { WrapperSeamless } from "layout-components";
import { Button, Card, CardContent, Tooltip } from "@material-ui/core";
import ViewIcon from "@material-ui/icons/Visibility";
import HistoryModal from "./HistoryModal";
import { useTranslation } from "localization/translation";
import { getQueryParam } from "helpers/getQueryParam";
import { inventoryDateFormat } from "helpers/inventoryDateFormat";
import { getLeftOver } from "helpers/getMeasurementName";
import useStore from "context";
import { FIXED_COUNT, MILLILITERS_VALUE } from "Constants";

const ViewItems = () => {
  const translation = useTranslation();
  const { language, weightValue, volumeValue } = useStore();
  const { id, good_id, did } = useParams();
  const [historyId, setHistoryId] = useState(null);
  const [items] = useGetData(Api.inventories.getById, id + "/" + good_id);

  const history = useHistory();
  const { state } = useLocation();

  const handleRoute = (id) => {
    history.push({
      search: `?id=${id}`,
    });
  };

  const closeHandler = () => {
    history.goBack();
  };

  useEffect(() => {
    setHistoryId(getQueryParam(history.location, "id"));
  }, [history.location.search]);

  return (
    <WrapperSeamless>
      <Card className="card-box mb-spacing-6-x2">
        <CardContent>
          <h6 className="font-weight-bold font-size-lg mb-0 text-black">
            {translation.inventory} #{did}
            <hr />
            {translation.total_left_over}{" "}
            {state?.leftover || items?.left_over?.toFixed(FIXED_COUNT)}
          </h6>
          <hr />
          <table className="table">
            <thead className="text-center">
              <tr>
                <th>{translation.barcode}</th>
                <th>{translation.full_bottle}</th>
                <th>{translation.bottle_count}</th>
                <th>{translation.left_over}</th>
                <th>{translation.status}</th>
                <th>{translation.created_at}</th>
                <th>{translation.updated_at}</th>
                <th>{translation.deleted_at}</th>
                <th>{translation.action}</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {items?.data?.map((item) => {
                return (
                  <tr key={item?.id}>
                    <td>{item?.barcode}</td>
                    <td>
                      {item?.measurement_unit === MILLILITERS_VALUE
                        ? item?.is_full
                          ? translation.full
                          : translation.open
                        : "-"}
                    </td>
                    <td>{item?.count ?? "-"}</td>
                    <td>
                      {getLeftOver({
                        unit: item?.measurement_unit,
                        totalLiters: item?.total_liters,
                        leftOver: item?.leftover,
                        language,
                        weight: weightValue,
                        volume: volumeValue,
                      })}
                    </td>
                    <td>
                      <span
                        className={
                          item?.added_after_completed
                            ? "blue_status"
                            : item?.status?.toLowerCase()
                        }
                      >
                        {translation[item?.status?.toLowerCase()]}
                      </span>
                    </td>
                    <td>
                      {item?.created_at &&
                        inventoryDateFormat(item?.created_at)}
                    </td>
                    <td>
                      {item?.updated_at &&
                        inventoryDateFormat(item?.updated_at)}
                    </td>
                    <td>
                      {item?.deleted_at &&
                        inventoryDateFormat(item?.deleted_at)}
                    </td>
                    <td>
                      {["Edited", "Deleted"].includes(item?.status) && (
                        <Tooltip placement={`top`} title={`View history`}>
                          <Button
                            onClick={() => handleRoute(item?.id)}
                            className="btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                          >
                            <ViewIcon color="primary" />
                          </Button>
                        </Tooltip>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardContent>
      </Card>
      {historyId && (
        <HistoryModal
          open={!!historyId}
          historyId={historyId}
          closeHandler={closeHandler}
        />
      )}
    </WrapperSeamless>
  );
};

export default ViewItems;
