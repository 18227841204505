import React, { useState } from "react";
import { InputAdornment, IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import Input from "components/login-input";

const PasswordInput = ({
  name,
  value,
  onChange,
  placeholder = "Password",
  error,
  helpText,
  autoComplete,
  required,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <Input
      type={showPassword ? "text" : "password"}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      autoComplete={autoComplete}
      required={required}
      error={error}
      helpText={helpText}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={togglePasswordVisibility}
              edge="end"
              style={{ marginTop: 0, padding: "12px" }}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

PasswordInput.defaultProps = {
  placeholder: "Password",
  error: false,
  helpText: "",
  autoComplete: "off",
  required: false,
};

export default PasswordInput;
