import React from "react";
import {
  Button,
  Dialog,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useTranslation } from "localization/translation";
import { ROUTES } from "Constants";

export const ChooseStore = ({
  setOpen,
  open,
  setStoreId,
  onClickHandler,
  data,
  isWarning,
  unresolvedCount,
  unresolvedProductsIds,
  title,
  isFirstRender,
  deletedInventoriesCount,
  unresolvedProductMeasurementUnit,
}) => {
  const translation = useTranslation();

  const handleChange = (e) => {
    setStoreId(e.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      classes={{ paper: "shadow-lg rounded" }}
    >
      <div className="text-center p-5 width_500">
        <h4 className="font-weight-bold mt-4">{title}</h4>
        {isFirstRender ? (
          <>
            <div className="select_store">
              <div className="unresolved_message">
                <span>{`${translation.you_have} ${unresolvedCount} ${translation.unresolved_products}`}</span>
                {!!deletedInventoriesCount && (
                  <span>{`${translation.but} ${deletedInventoriesCount}${translation.have_been_deleted}`}</span>
                )}
              </div>
            </div>
            <div className="pt-4">
              <Button
                onClick={() => setOpen(false)}
                className="btn-neutral-secondary btn-pill mx-1"
              >
                <span className="btn-wrapper--label">{translation.cancel}</span>
              </Button>
              <Button className="btn-success btn-pill mx-1">
                <Link
                  to={{
                    pathname: ROUTES.iiko_unresolved,
                    state: {
                      goods: unresolvedProductsIds,
                      goods_unit: unresolvedProductMeasurementUnit,
                    },
                  }}
                  className="resolve_button"
                >
                  <span className="btn-wrapper--label btn-action text-capitalize">
                    {translation.resolve}
                  </span>
                </Link>
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="select_store">
              <FormControl>
                <InputLabel htmlFor="grouped-native-select">
                  {translation.store}
                </InputLabel>
                <Select
                  native
                  defaultValue=""
                  id="grouped-native-select"
                  onChange={handleChange}
                  disabled={!data?.length}
                >
                  <option aria-label="None" value="" />
                  {data?.map((branch) => (
                    <optgroup label={branch?.name} key={branch?.id}>
                      {branch?.stores?.map((store) => (
                        <option value={store?.store_id} key={store?.id}>
                          {store?.name}
                        </option>
                      ))}
                    </optgroup>
                  ))}
                </Select>
              </FormControl>
              {isWarning && (
                <div className="unresolved_message">
                  <span>{`${translation.you_have} ${unresolvedCount} `}</span>
                  <Link
                    to={{
                      pathname: ROUTES.iiko_unresolved,
                      state: {
                        goods: unresolvedProductsIds,
                      },
                    }}
                  >
                    <span className="unresolved_products">
                      {translation.unresolved_products}
                    </span>
                  </Link>
                </div>
              )}
            </div>
            <div className="pt-4">
              <>
                <Button
                  onClick={() => setOpen(false)}
                  className="btn-neutral-secondary btn-pill mx-1"
                >
                  <span className="btn-wrapper--label">
                    {translation.cancel}
                  </span>
                </Button>
                <Button
                  onClick={onClickHandler}
                  className="btn-success btn-pill mx-1"
                >
                  <span className="btn-wrapper--label btn-action text-capitalize">
                    {translation.send}
                  </span>
                </Button>
              </>
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
};
